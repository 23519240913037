const logResponse = (response) => {
  console.log("Response:", response);
};

export async function createClinicalNotesCustomTemplate(title, text, file) {
  const formData = new FormData();
  formData.append("title", title);
  formData.append("text", text);
  formData.append("file", file);
  const response = await store.api.post(
    "/ai/clinical/clinical-notes-writer/custom-template",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  logResponse(response);
  return response;
}

export async function saveClinicalNotesCustomTemplate(title, content) {
  const response = await store.api.post(
    "/ai/clinical/clinical-notes-writer/save-template",
    {
      title,
      content,
    }
  );
  logResponse(response);
  return response;
}

export async function getClinicalNotesCustomTemplates() {
  const response = await store.api.get(
    "/ai/clinical/clinical-notes-writer/get-templates"
  );
  logResponse(response);
  return response;
}

export async function generateClinicalNotes(payload) {
  const response = await store.api.post(
    "/ai/clinical/clinicalnoteswriter",
    payload
  );
  logResponse(response);
  return response;
}

export async function generateClinicalNotesWithCustomTemplate(payload) {
  const response = await store.api.post(
    "/ai/clinical/clinical-notes-writer/custom",
    payload
  );
  logResponse(response);
  return response;
}

export async function loadClinicalNotesHistories() {
  const response = await store.api.get(
    "/histories?page=1&toolType=Note Writer"
  );
  logResponse(response);
  return response;
}
