import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

export function Pagination({ items, total, perPage, currentPage }) {
  if (
    isNaN(currentPage) ||
    currentPage == "" ||
    (currentPage && currentPage < 1)
  ) {
    throw new Error("Invalid page number");
  } else {
    currentPage ??= 1;
    currentPage = parseInt(currentPage);
  }

  const count = items.length;
  const firstItem = count > 0 ? (currentPage - 1) * perPage + 1 : null;
  const lastItem = count > 0 ? firstItem + count - 1 : null;

  const url = (page) => {
    const path = `${window.location.href.split("?")[0]}`;
    const url = new URL(path);

    url.searchParams.append("page", page);

    return url.toString();
  };

  const lastPage = Math.max(parseInt(Math.ceil(total / perPage)), 1);
  const onFirstPage = currentPage == 1;
  const hasMorePages = currentPage < lastPage;
  const previousPageUrl = currentPage > 1 ? url(currentPage - 1) : null;
  const nextPageUrl = hasMorePages ? url(currentPage + 1) : null;

  const range = (start, end, step = 1) => {
    const length = (end - start) / step + 1;

    return Array.from({ length }, (_, value) => start + value * step);
  };

  const urlRange = (start, end) => {
    return range(start, end).map((value) => {
      return { page: value, url: url(value) };
    });
  };

  const getLeft = () => {
    return urlRange(1, 1);
  };

  const getRight = () => {
    return urlRange(lastPage, lastPage);
  };

  const pageLinks = () => {
    const onEachSide = 2;

    if (lastPage < onEachSide * 2 + 8) {
      return {
        left: urlRange(1, lastPage),
        middle: null,
        right: null,
      };
    }

    const window = onEachSide + 2;

    if (currentPage <= window) {
      return {
        left: urlRange(1, window + onEachSide),
        middle: null,
        right: getRight(),
      };
    } else if (currentPage > lastPage - window) {
      return {
        left: getLeft(),
        middle: null,
        right: urlRange(lastPage - (window + (onEachSide - 1)), lastPage),
      };
    }

    return {
      left: getLeft(),
      middle: urlRange(currentPage - onEachSide, currentPage + onEachSide),
      right: getRight(),
    };
  };

  const elements = () => {
    const links = pageLinks();

    return [
      links.left,
      Array.isArray(links.middle) ? "..." : null,
      links.middle,
      Array.isArray(links.right) ? "..." : null,
      links.right,
    ].filter((link) => link !== null);
  };

  return (
    <nav className="flex items-center justify-between mt-4">
      {lastPage > 1 && (
        <div className="flex justify-end flex-1 sm:hidden">
          {onFirstPage ? (
            <span className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default leading-5 rounded-md">
              Previous
            </span>
          ) : (
            <a
              href={previousPageUrl}
              className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-gray-500 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            >
              Previous
            </a>
          )}

          {hasMorePages ? (
            <a
              href={nextPageUrl}
              className="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-gray-500 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
            >
              Next
            </a>
          ) : (
            <span className="ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default leading-5 rounded-md">
              Next
            </span>
          )}
        </div>
      )}

      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        {total > 1 && (
          <div>
            <p className="text-sm text-gray-700 leading-5">
              Showing
              {firstItem ? (
                <>
                  <span className="font-medium">{" " + firstItem}</span>
                  {" to "}
                  <span className="font-medium">{lastItem + " "}</span>
                </>
              ) : (
                <>{count} </>
              )}
              of
              <span className="font-medium">{" " + total + " "}</span>
              results
            </p>
          </div>
        )}

        {lastPage > 1 && (
          <div>
            <span className="relative z-0 inline-flex shadow-md rounded-lg">
              {onFirstPage ? (
                <span>
                  <span className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md leading-5 cursor-default">
                    <ChevronLeftIcon className="h-5 w-5" />
                  </span>
                </span>
              ) : (
                <a
                  href={previousPageUrl}
                  className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md leading-5 hover:bg-gray-100 hover:text-gray-400 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </a>
              )}

              {elements().map((element, index) => {
                return typeof element === "string" ? (
                  <span key={index}>
                    <span className="-ml-px relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 cursor-default">
                      {element}
                    </span>
                  </span>
                ) : (
                  Array.isArray(element) &&
                    element.map((value) => {
                      return value.page === currentPage ? (
                        <span key={value.page}>
                          <span className="-ml-px relative inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-500 border border-gray-300 cursor-default leading-5">
                            {value.page}
                          </span>
                        </span>
                      ) : (
                        <a
                          key={value.page}
                          href={value.url}
                          className="-ml-px relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:bg-gray-100 hover:text-gray-500 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                        >
                          {value.page}
                        </a>
                      );
                    })
                );
              })}

              {hasMorePages ? (
                <a
                  href={nextPageUrl}
                  className="-ml-px relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md leading-5 hover:bg-gray-100 hover:text-gray-400 focus:z-10 focus:outline-none focus:ring ring-gray-300 focus:border-blue-300 active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </a>
              ) : (
                <span>
                  <span className="-ml-px relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md leading-5 cursor-default">
                    <ChevronRightIcon className="h-5 w-5" />
                  </span>
                </span>
              )}
            </span>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Pagination;
