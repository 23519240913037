import { makeObservable, observable, action, computed } from "mobx";
import { EventEmitter } from "events";
import { updateHistory, fetchThreadHistories } from "./api.service.v2";
import {
  runAssistant,
  createThread,
  addMessage,
  fetchMessages,
} from "./azure-api-service";
import { deleteHistory } from "../../components/History";
import { PERSONAS } from "./Personas";

class NavixScribeV2Store extends EventEmitter {
  constructor(rootStore) {
    super();
    this.rootStore = rootStore;

    if (!localStorage.getItem("selectedPersona")) {
      this.setSelectedPersona({ persona: "Therapist", customResponse: "" });
    }
    if (!localStorage.getItem("selectedPronouns")) {
      this.setPronouns("He/She");
    }
    if (!localStorage.getItem("selectedPatientName")) {
      this.setPatientName("Client");
    }

    this.loadSelectedPersona();
    this.loadPronouns();
    this.loadNoteStyle();
    this.loadPatientName();

    makeObservable(this, {
      tool: observable,
      activeSection: observable,
      activeRecord: observable,
      outputActiveRecord: observable,
      triggerSection: observable,
      selectedPersona: observable,
      isRecording: observable,
      recordingStartTime: observable,
      elapsedTime: observable,
      savedDuration: observable,
      threads: observable,
      selectedThread: observable,
      messages: observable,
      loading: observable,
      lastMessageTimestamp: observable,
      isAwaitingResponse: observable,
      output: observable,
      utterances: observable,
      outputType: observable,
      inputActiveRecord: observable,
      inputDocument: observable,
      inputForm: observable,
      inputUploads: observable,
      inputHistories: observable,
      clinicalNotesHistories: observable,
      questionHistories: observable,
      summaryHistories: observable,
      selectedPronouns: observable,
      selectedNoteStyle: observable,
      selectedPatientName: observable,
      awsUrl: observable,
      audioDuration: observable,

      startRecording: action,
      stopRecording: action,
      updateElapsedTime: action,
      setSelectedPersona: action,
      loadSelectedPersona: action,
      setPronouns: action,
      setNoteStyle: action,
      setPatientName: action,
      clearSetting: action,
      setTool: action,
      setInputActiveRecord: action,
      setInputDocument: action,
      setActiveHistory: action,
      setInputHistories: action,
      setClinicalNotesHistories: action,
      setQuestionHistories: action,
      setSummaryHistories: action,
      setInputUploads: action,
      setOutput: action,
      setInputForm: action,
      setOutputActiveRecord: action,
      setShowInputAccordion: action,
      handleSaveFeedbackValue: action,
      setTriggerSection: action,
      clear: action,
      fetchThreads: action,
      selectThread: action,
      fetchThreadMessages: action,
      setMessages: action,
      setSelectedThread: action,
      setLoading: action,
      setIsAwaitingResponse: action,
      sendMessage: action,
      createNewThread: action,
      handleDelete: action,
      loadPronouns: action,
      loadNoteStyle: action,
      loadPatientName: action,
      setAwsUrl: action,
      setAudioDuration: action,
    });
  }

  tool = {};
  activeSection = "input";
  activeRecord = "";
  outputActiveRecord = "";
  triggerSection = "";
  selectedPersona = null;
  selectedPronouns = null;
  selectedNoteStyle = null;
  selectedPatientName = null;

  isRecording = false;
  recordingStartTime = 0;
  elapsedTime = 0;
  savedDuration = 0;

  threads = [];
  selectedThread = null;
  messages = [];
  loading = false;
  lastMessageTimestamp = null;
  isAwaitingResponse = false;

  awsUrl = null;
  audioDuration = 0;

  startRecording = () => {
    this.isRecording = true;
    this.recordingStartTime = Date.now();
    this.updateElapsedTime();
  };
  stopRecording = () => {
    this.isRecording = false;
    this.savedDuration = this.elapsedTime;
    this.recordingStartTime = 0;
    this.elapsedTime = 0;
  };

  updateElapsedTime = () => {
    if (this.isRecording) {
      this.elapsedTime = Date.now() - this.recordingStartTime;
      setTimeout(this.updateElapsedTime, 1000);
    }
  };

  setSelectedPersona = (val) => {
    if (val.persona !== "Custom") {
      const selectedPersonaObj = this.PERSONAS.find(
        (p) => p.name === val.persona
      );
      if (selectedPersonaObj) {
        val.customResponse = selectedPersonaObj.description;
      }
    }
    this.selectedPersona = val;
    localStorage.setItem("selectedPersona", JSON.stringify(val));
  };

  loadSelectedPersona = () => {
    const savedPersona = localStorage.getItem("selectedPersona");
    if (savedPersona) {
      this.selectedPersona = JSON.parse(savedPersona);
    }
  };

  setPronouns = (val) => {
    this.selectedPronouns = val;
    localStorage.setItem("selectedPronouns", val);
  };

  setNoteStyle = (val) => {
    this.selectedNoteStyle = val;
    localStorage.setItem("selectedNoteStyle", val);
  };

  setPatientName = (val) => {
    this.selectedPatientName = val;
    if (val === "") {
      localStorage.removeItem("selectedPatientName");
    } else {
      localStorage.setItem("selectedPatientName", val);
    }
  };

  getPronouns = () => {
    return localStorage.getItem("selectedPronouns") || "";
  };

  setPronouns = (val) => {
    this.selectedPronouns = val;
    localStorage.setItem("selectedPronouns", val);
  };

  setNoteStyle = (val) => {
    this.selectedNoteStyle = val;
    localStorage.setItem("selectedNoteStyle", val);
  };

  setPatientName = (val) => {
    this.selectedPatientName = val;
    localStorage.setItem("selectedPatientName", val);
  };

  getNoteStyle = () => {
    return localStorage.getItem("selectedNoteStyle") || "";
  };

  setPronouns = (val) => {
    this.selectedPronouns = val;
    localStorage.setItem("selectedPronouns", val);
  };

  setNoteStyle = (val) => {
    this.selectedNoteStyle = val;
    localStorage.setItem("selectedNoteStyle", val);
  };

  setPatientName = (val) => {
    this.selectedPatientName = val;
    localStorage.setItem("selectedPatientName", val);
  };

  getPatientName = () => {
    return localStorage.getItem("selectedPatientName") || "";
  };

  clearSetting = (key) => {
    localStorage.removeItem(key);
    if (key === "selectedPersona") {
      this.selectedPersona = null;
    } else if (key === "selectedPronouns") {
      this.selectedPronouns = null;
    } else if (key === "selectedNoteStyle") {
      this.selectedNoteStyle = null;
    } else if (key === "selectedPatientName") {
      this.selectedPatientName = null;
    }
  };

  setTool = (tool) => {
    this.tool = tool;
  };

  output = "";
  utterances = "";
  outputType = "";
  inputActiveRecord = "";
  inputDocument = null;
  inputForm = {
    title: "",
    inputText: "",
    file: "",
    filename: "",
    audioDuration: 0,
    audioDurationString: "",
    output: "",
  };
  inputUploads = [];
  inputHistories = [];
  clinicalNotesHistories = [];
  questionHistories = [];
  summaryHistories = [];

  get inputOutput() {
    return this.inputForm.output;
  }

  setInputActiveRecord = (val) => {
    console.log("setInputActiveRecord - val:", val);
    if (val.output === null) {
      console.error("setInputActiveRecord - val.output is null", val);
    }
    this.inputActiveRecord = val;
    console.log(
      "setInputActiveRecord - this.inputActiveRecord:",
      this.inputActiveRecord
    );
  };

  setInputDocument = (val) => {
    this.inputDocument = val;
  };

  setActiveHistory = (historyId, section) => {
    this.activeRecord = historyId;
    this.activeSection = section;
    console.log(
      "setActiveHistory - History ID:",
      historyId,
      "Section:",
      section
    );
  };

  /**
   * Used for setting the histories
   * instead of using local state.
   */
  setInputHistories = (val) => {
    this.inputHistories = val;
  };

  setClinicalNotesHistories = (val) => {
    this.clinicalNotesHistories = val;
  };

  setQuestionHistories = (val) => {
    this.questionHistories = val;
  };

  setSummaryHistories = (val) => {
    this.summaryHistories = val;
  };

  setInputUploads = (val) => {
    this.inputUploads = val;
  };

  setOutput = (val, utterances, type) => {
    this.output = val;
    this.utterances = utterances;
    this.outputType = type;
  };

  setInputForm = (name, value) => {
    this.inputForm[name] = value;
  };

  setOutputActiveRecord = (history) => {
    this.outputActiveRecord = history;
  };

  setShowInputAccordion = (val) => {
    this.showInputAccordion = val;
  };

  *handleSaveFeedbackValue(value, id) {
    try {
      yield updateHistory(id, { feedbackValue: value });

      if (this.outputActiveRecord._id === id) {
        this.outputActiveRecord.feedbackValue = value;
      }

      return {
        success: true,
        message: "Successfully submitted your feedback.",
      };
    } catch (error) {
      return {
        success: false,
        message: "Something went wrong!",
      };
    }
  }

  setTriggerSection = (val) => {
    this.triggerSection = val;
  };

  clear = () => this.setTool({});

  fetchThreads = async () => {
    const histories = await fetchThreadHistories();
    this.threads = histories.result.map((history) => ({
      id: history.threadId,
      title: history.title,
    }));
  };

  selectThread = async (threadId) => {
    if (this.selectedThread !== threadId) {
      this.setLoading(true);
      this.selectedThread = threadId;
      await this.fetchThreadMessages(threadId);
      this.setLoading(false);
    }
  };

  fetchThreadMessages = async (threadId) => {
    try {
      const fetchedMessages = await fetchMessages(threadId);
      this.setMessages(fetchedMessages);
      if (fetchedMessages.length > 0) {
        const lastTimestamp = fetchedMessages[0].created_at;
        this.lastMessageTimestamp = lastTimestamp;
      }
    } catch (error) {
      console.error("Failed to fetch messages:", error);
      this.setMessages([]);
    }
  };

  setMessages(messages) {
    this.messages = messages;
  }

  setSelectedThread(threadId) {
    this.selectedThread = threadId;
    this.emit("threadAdded");
  }

  setLoading(value) {
    this.loading = value;
  }

  setIsAwaitingResponse(value) {
    this.isAwaitingResponse = value;
  }

  async sendMessage(message) {
    if (this.selectedThread) {
      this.setIsAwaitingResponse(true);
      await addMessage(this.selectedThread, "user", message);
      const response = await runAssistant(
        message,
        this.selectedThread,
        "asst_HagPjnY7H8EYT5gRm8o8DSsX",
        ""
      );
      this.messages.push({ role: "user", content: message });
      this.messages.push({ role: "assistant", content: response.content });
      this.setIsAwaitingResponse(false);
    }
  }

  async createNewThread() {
    const threadResponse = await createThread();
    const threadId = threadResponse.thread_id;
    this.threads.push({ id: threadId, title: "New Conversation" });
    await this.selectThread(threadId);
  }

  async handleDelete(id) {
    try {
      const success = await deleteHistory(this.rootStore.store, id);
      if (success) {
        console.log("History deleted successfully");
      } else {
        console.error("Failed to delete history");
      }
    } catch (error) {
      console.error("Error in handleDelete:", error);
    }
  }

  PERSONAS = PERSONAS;

  get personaPrompt() {
    if (!this.selectedPersona) return "";

    if (this.selectedPersona.persona === "Custom") {
      return this.selectedPersona.customResponse;
    }

    const selectedPersonaObj = this.PERSONAS.find(
      (p) => p.name === this.selectedPersona.persona
    );
    return selectedPersonaObj ? selectedPersonaObj.prompt : "";
  }

  get combinedPrompt() {
    const personaPrompt = this.personaPrompt;
    const pronouns = this.getPronouns();
    const noteStyle = this.getNoteStyle();
    const patientName = this.getPatientName();

    const pronounsPrompt = pronouns ? `Use '${pronouns}' for pronouns.` : "";
    const noteStylePrompt = noteStyle
      ? noteStyle === "Bullet-point"
        ? "Use bullet points."
        : "Avoid using bullet points."
      : "";
    const patientNamePrompt = patientName
      ? `Replace patient names with the word '${patientName}'.`
      : "";

    return `${personaPrompt}\n\n${pronounsPrompt}\n\n${noteStylePrompt}\n\n${patientNamePrompt}`.trim();
  }

  loadPronouns = () => {
    const savedPronouns = localStorage.getItem("selectedPronouns");
    if (savedPronouns) {
      this.selectedPronouns = savedPronouns;
    }
  };

  loadNoteStyle = () => {
    const savedNoteStyle = localStorage.getItem("selectedNoteStyle");
    if (savedNoteStyle) {
      this.selectedNoteStyle = savedNoteStyle;
    }
  };

  loadPatientName = () => {
    const savedPatientName = localStorage.getItem("selectedPatientName");
    if (savedPatientName) {
      this.selectedPatientName = savedPatientName;
    }
  };

  setAwsUrl = (url) => {
    this.awsUrl = url;
  };

  setAudioDuration = (duration) => {
    console.log("Setting audio duration:", duration);
    this.audioDuration = duration;
  };
}

export const navixScribeV2Store = new NavixScribeV2Store();
